<template>
  <div class="login page">
        
        <section class="content">

            <section>
                <div class="section">
                    <div v-if="showLoginForm" @keyup.enter="login">
                        <img src="@/assets/img/logo_color.png" alt="logo" class="logo" @click="changeURL('/')">
                        <input v-model.trim="loginForm.email" type="email" placeholder="Email">
                        <input v-model.trim="loginForm.password" type="password" placeholder="Password">
                        <small v-if="errorMsg != ''" class="error">*{{errorMsg}}</small>
                        <button class="button" @click="login">
                            <span v-if="performingRequest">Loading...</span>
                            <span v-else>Sign In</span>
                        </button>
                        <button class="button gSignIn" @click="googleLogin">
                            <span>Google Sign In</span>
                        </button>
                        <div class="extras">
                            <!-- <div><small>Forgot Password</small></div>
                            <hr> -->
                            <div><small @click="toggleForm">Request Account</small></div>
                        </div>
                    </div>
                    <div v-else>
                        <img src="@/assets/img/logo_color.png" alt="logo" class="logo" @click="changeURL('/')">
                        <input v-model.trim="signupForm.firstName" type="text" placeholder="First Name">
                        <input v-model.trim="signupForm.lastName" type="text" placeholder="Last Name">
                        <input v-model.trim="signupForm.email" type="email" placeholder="Email">
                        <input v-model.trim="signupForm.phone" type="text" placeholder="Phone #">
                        <input v-model.trim="signupForm.address" type="text" placeholder="Street Address">
                        <input v-model.trim="signupForm.password" type="password" placeholder="Password">
                        <input v-model.trim="signupForm.confirmPassword" type="password" placeholder="Confirm Password">
                        <p el>Account type</p>
                        <select v-model="signupForm.accountType">
                            <option value="resident">Resident</option>
                            <option value="renter">Renter</option>
                        </select>
                        <small v-if="errorMsg != ''" class="error">*{{errorMsg}}</small>
                        <button class="button" @click="signup">
                            <span v-if="performingRequest">Loading...</span>
                            <span v-else>Request My Account</span>
                        </button>
                        <div class="extras">
                            <a @click="toggleForm">Log In</a>
                        </div>
                    </div>
                </div>

            </section>
        </section>
            
  </div>

</template>

<script>
// const fb = require('../firebase.js')
// const db = fb.firestore()
// var db = fb.firestore;
import {auth,db,provider} from '../firebase'


export default {
  name: 'login',
  data(){
    return {
        loginForm: {
            email: '',
            password: ''
        },
        signupForm: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            address: '',
            accountType:'resident',
            password: '',
            confirmPassword: '',
        },
        showLoginForm: true,
        performingRequest: false,
        errorMsg: '',
    }
  },
  methods:{
      toggleForm(){
          this.showLoginForm = !this.showLoginForm;
          this.errorMsg = '';
      },
      login() {
          var x = this;
          x.validateLoginInput();
          if(x.errorMsg == ''){
              this.performingRequest = true;
                auth.signInWithEmailAndPassword(this.loginForm.email, this.loginForm.password).then(() => {
                    //user.user
                    var data = {
                        uid: auth.currentUser.uid,
                        email: auth.currentUser.email,
                        emailVerified: auth.currentUser.emailVerified,
                        displayName: auth.currentUser.displayName,
                        phoneNumber: auth.currentUser.phoneNumber,
                        photoURL: auth.currentUser.photoURL
                    };
                    this.$store.commit('setCurrentUser', data);
                    this.$store.dispatch('fetchUserProfile')
                    this.performingRequest = false;
                    this.errorMsg = '';
                    x.$router.push('/dashboard');
                }).catch(err => {
                    console.log(err);
                    this.performingRequest = false;
                    this.errorMsg = err.message;
                })
          }
            
        },
      googleLogin() {
          var x = this;

            auth.signInWithPopup(provider).then(() => { //(result) => {
                // var credential = result.credential;

                // This gives you a Google Access Token. You can use it to access the Google API.
                // var token = credential.accessToken;
                // The signed-in user info.
                // var user = result.user;
                // x.$router.push('/dashboard');
                var data = {
                    uid: auth.currentUser.uid,
                    email: auth.currentUser.email,
                    emailVerified: auth.currentUser.emailVerified,
                    displayName: auth.currentUser.displayName,
                    phoneNumber: auth.currentUser.phoneNumber,
                    photoURL: auth.currentUser.photoURL
                };
                
                //   check if user exists
                var docRef = db.collection("users").doc(auth.currentUser.uid);
                docRef.get().then((doc) => {
                    if (doc.exists) {
                        x.$store.commit('setCurrentUser', data);
                        x.$store.dispatch('fetchUserProfile');
                        x.$router.push('/dashboard');
                    } else {
                        db.collection("users").doc(auth.currentUser.uid).set({
                            temp: true,
                        }).then(() => {
                            db.collection("users").doc(auth.currentUser.uid).delete();
                        });
                        
                        alert('You must request an account before you can use Google sign in.');
                    }
                }).catch((error) => {
                    console.log("Error getting document:", error);
                });
            }).catch((error) => {
                console.log(error);
                // Handle Errors here.
                // var errorCode = error.code;
                // var errorMessage = error.message;
                // The email of the user's account used.
                // var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                // var credential = error.credential;
                // ...
            });




            // auth.signInWithEmailAndPassword(this.loginForm.email, this.loginForm.password).then(() => {
            //     var data = {
            //         uid: auth.currentUser.uid,
            //         email: auth.currentUser.email,
            //         emailVerified: auth.currentUser.emailVerified,
            //         displayName: auth.currentUser.displayName,
            //         phoneNumber: auth.currentUser.phoneNumber,
            //         photoURL: auth.currentUser.photoURL
            //     };
            //     this.$store.commit('setCurrentUser', data);
            //     this.$store.dispatch('fetchUserProfile')
            //     this.performingRequest = false;
            //     this.errorMsg = '';
            //     x.$router.push('/dashboard');
            // }).catch(err => {
            //     console.log(err);
            //     this.performingRequest = false;
            //     this.errorMsg = err.message;
            // })
        },
        validateLoginInput(){
            let x = this;
            x.errorMsg = '';
            if(x.loginForm.email == '' || x.loginForm.password == ''){
                x.errorMsg = 'Please fill out all of the fields.';
            }
            let regex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,10}');
            if(!regex.test(x.loginForm.email)){
                x.errorMsg = 'The email address is not formatted properly.';
            }
        },
        validateSignupInput(){
            let x = this;
            x.errorMsg = '';
            if(
                x.signupForm.firstName == '' ||
                x.signupForm.lastName == '' ||
                x.signupForm.email == '' ||
                x.signupForm.address == '' ||
                x.signupForm.phone == '' ||
                x.signupForm.password == '' ||
                x.signupForm.confirmPassword == ''
            ){
                x.errorMsg = 'Please fill out all of the fields.';
            }
            let regex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,10}');
            if(!regex.test(x.signupForm.email)){
                x.errorMsg = 'The email address is not formatted properly.';
            }
            if(x.signupForm.password !== x.signupForm.confirmPassword){
                x.errorMsg = "The password confirmation doesn't match your password";
            }
            if(x.signupForm.password.length < 5 || x.signupForm.password.length > 12){
                x.errorMsg = "Password must be 5-12 characters in length";
            }
            if(/^[0-9]+$/.test(x.signupForm.phone) == false){
                x.errorMsg = "Phone number must contain only numbers";
            }
        },
        signup() {
            var x = this;
            x.validateSignupInput();
            if(x.errorMsg == ''){
                auth.createUserWithEmailAndPassword(this.signupForm.email, this.signupForm.password).then(user => {
                    //let user = user.user;
                    console.log(user.user);
                    x.$store.commit('setCurrentUser', user.user)
                    
                    if(x.signupForm.email != null){
                        db.collection('users').doc(user.user.uid).set({
                            firstName: x.signupForm.firstName,
                            lastName: x.signupForm.lastName,
                            email: x.signupForm.email,
                            address: x.signupForm.address,
                            accountType: x.signupForm.accountType,
                            phone: x.signupForm.phone,
                            approvedAccount: false,
                            permissions: [],
                            role:'',
                            createdOn: Date.now(),
                        }).then(() => {
                            x.$store.dispatch('fetchUserProfile')
                            x.$router.push('/dashboard')
                        }).catch(err => {
                            console.log(err)
                        })
                    }
                    
                    x.errorMsg = '';
                }).catch(err => {
                    console.log(err);
                    x.errorMsg = err.message;
                })
            }
            
        },
        changeURL(loc){
            this.$router.push(loc)
        }
  },
  computed:{
      slideshow(){
          return this.$store.state.slideshowData
      }
  },
  mounted(){

    //   var x = this;
    //   if(auth.currentUser != null){
    //       x.$router.push('/dashboard');
    //   }
    
    //   auth.onAuthStateChanged(function(user) {
    //         if (user) {
    //             // User is signed in.
    //             x.user = user;
    //             x.$router.push('/dashboard');
    //         }
    //     });
      
  }
}

// db.collection("playlists").add({
//       accountID: 'test',
//       playlistName: 'folderName',
//       timestamp: 'Date',
//       transitionType: ''
//   }).then(function(docRef) {
//       console.log("Document written with ID: ", docRef.id);
//   }).catch(function(error) {
//       alert("Error creating playlist: ", error);
//   });
</script>
<style scoped>
.logo{
    cursor:pointer;
}
.login .content{
    all: unset;

    display: unset;
    justify-content: unset;
    align-items: unset;
    min-height: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    margin-top:0;

}
.login .content .section{
    background-color:#fff;
    border-radius:5px;
    box-shadow: 0 3px 15px rgba(0,0,0,0.2);
}
.login .content .section > *{
    display:grid;
    grid-gap:10px;
    justify-content: center;
    justify-items: center;
    width:100%;
    max-width:300px;
}
.login .button{
    width:100%;
    transition:.2s;
}
.login input,
.login select{
    width:100%;
    height:35px;
    display:flex;
    align-items: center;
    padding:0 15px;
    border-radius:3px;
    background-color: rgba(0,0,0,0.05);
    border: 1px solid rgba(0,0,0,0);
    transition:.2s;
}
.login input:focus,
.login select:focus{
    border:1px solid rgba(0,0,0,0.25);
}
.login .extras{
    display:grid;
    justify-items: center;
    grid-gap:0px;
    color:rgba(0,0,0,0.7);
    width:100%;
}
.extras small{
font-size:14px !important;
cursor: pointer;
}
hr{
    border:0;
    height:1px;
    background-color: rgba(0,0,0,0.25);
    width:100%;
}
.error{
    color:darkred;
}
.gSignIn{
    background-color: #fff;
    border: 1px solid #b9b9b9;
    color: #484848;
    background-image: url(https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/200px-Google_%22G%22_Logo.svg.png);
    background-size: 25px;
    background-repeat: no-repeat;
    background-position: 10px center;
    transition: 0.3s;
}
.gSignIn:hover{
    background-color: #f1f1f1;
}
</style>